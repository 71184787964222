import { useStaticQuery, graphql, Link } from 'gatsby'
import * as React from 'react'
import Hero from '../components/Hero'
import Seo from '../components/SEO'
import Button from '../components/Button'

const Outsourcing = () => {
  const data = useStaticQuery(graphql`
    query {
      categories: allFile(
        filter: { sourceInstanceName: { eq: "categories" } }
      ) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              id
              slug
              title
              css
              description
              ebook {
                size
                name
              }
            }
          }
        }
      }
      posts: allFile(
        filter: {
          sourceInstanceName: { eq: "posts" }
          childMarkdownRemark: { frontmatter: { shown_on_faq: { eq: true } } }
        }
      ) {
        nodes {
          relativePath
          childMarkdownRemark {
            frontmatter {
              category
              title
              slug
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <Seo title="FAQ" />
      <Hero className="text-center my-8 ">
        <h1 className="typo-h2 mb-4 z-10">
          Frequenty asked questions about outsourcing
        </h1>
        <p className="typo-normal max-w-5xl mx-auto">
          For many companies hiring developers overseas is a new thing. Before
          building Pairing, we were in the same situation. We tried a lot, and
          document what we learned, so you have an easier time navigating the
          possibilities.
        </p>
        <div className="flex justify-center	max-w-8xl mt-8 flex-wrap	gap-4">
          {data.categories.nodes.map((elem) => (
            <Link to={elem.childMarkdownRemark.frontmatter.slug}>
              <Button
                variant="faq_primary_button"
                size="normal"
                className="w-56"
                style={{ maxWidth: '212px', width: '100%', height: '70px' }}
              >
                {elem.childMarkdownRemark.frontmatter.title}
              </Button>
            </Link>
          ))}
        </div>
      </Hero>
      <div
        className="flex gap-4 flex-col px-2"
        // style={{ paddingLeft: '10vw', paddingRight: '10vw' }}
      >
        {data.categories.nodes.map((elem) => (
          <div
            className="rounded-xl bg-gray_faq_block dark:bg-dark_bg-light  mx-auto md:p-16 p-10"
            id={elem.childMarkdownRemark.frontmatter.id}
            style={{ maxWidth: '1414px', width: '100%' }}
          >
            <div className="flex flex-col gap-4 md:flex-row justify-between">
              <div className="" style={{ width: 'fit-content' }}>
                <h2
                  className="typo-h2 text-xl md:text-4xl"
                  style={{ color: '#155A73' }}
                >
                  {elem.childMarkdownRemark.frontmatter.title}
                </h2>
                <p className="text-xs md:text-sm">
                  {elem.childMarkdownRemark.frontmatter.description}
                </p>
              </div>
              <div className="flex flex-col gap-4 " style={{ minWidth: '60%' }}>
                {data.posts.nodes.map((post) => {
                  return post.childMarkdownRemark.frontmatter.category ===
                    elem.childMarkdownRemark.frontmatter.id ? (
                    <Link
                      to={post.childMarkdownRemark.frontmatter.slug}
                      className="rounded-md py-2 px-4 bg-light_gray_faq_block text-xs md:text-base dark:bg-dark_bg-lighter"
                      style={{
                        // background: '#F1F1F1',
                        textAlign: 'center',
                        fontWeight: '600',
                      }}
                    >
                      {post.childMarkdownRemark.frontmatter.title}
                    </Link>
                  ) : (
                    ''
                  )
                })}
                <Link
                  className="underline self-end mt-4"
                  to={elem.childMarkdownRemark.frontmatter.slug}
                >
                  Read all articles in this category
                </Link>
              </div>
            </div>
            <div className="mt-12 flex gap-8 justify-center items-center">
              <h4
                className="typo-h4 text-xs md:text-lg"
                style={{ /* fontSize: '24px',  */ height: 'fit-content' }}
              >
                {elem.childMarkdownRemark.frontmatter.ebook.name}
              </h4>
              <Button variant="faq_download_button" size="normal">
                Download Copy
              </Button>
              <p>{elem.childMarkdownRemark.frontmatter.ebook.size}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Outsourcing
